const apiKey = "AIzaSyDV2wGCrJoTd5wSq17EAcYffLW7cBuFqKc";

async function gapiLoaded() {
  try {
    gapi.load("client", initClient);
  } catch (error) {
    console.error("Error loading gapi client:", error);
  }
}

function initClient() {
  gapi.client
    .init({
      apiKey: apiKey,
      discoveryDocs: [
        "https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest",
      ],
    })
    .then(function () {
      listUpcomingEvents();
    })
    .catch((error) => {
      console.error("Error initializing gapi client", error);
      //Display Error to User.
    });
}

function listUpcomingEvents() {
  gapi.client.calendar.events
    .list({
      calendarId:
        "fd92fcfd178bf2bd25d622b7630ed8dab481be20c4dd7d773a183976dcad52b2@group.calendar.google.com", // Your public calendar ID
      timeMin: new Date().toISOString(),
      showDeleted: false,
      singleEvents: true,
      maxResults: 10,
      orderBy: "startTime",
    })
    .then(function (response) {
      const events = response.result.items;
      displayEvents(events);
    })
    .catch(function (error) {
      console.error("Error fetching events:", error);
      //Display an error message to the webpage here.
    });
}

function createGoogleMapsLink(locationString) {
  if (!locationString) {
    return ""; // Return an empty string if location is not provided
  }

  // Encode the location string for URL parameters
  const encodedLocation = encodeURIComponent(locationString);

  // Construct the Google Maps URL
  const googleMapsUrl = `https://www.google.com/maps/search/?api=1&query=${encodedLocation}`;

  return googleMapsUrl;
}

async function displayEvents(events) {
  const calendarContainer = document.getElementById("gcal-container");
  calendarContainer.innerHTML = "";

  if (events.length === 0) {
    const noEvents = document.createElement("p");
    noEvents.textContent = "There are no upcoming events.";
    calendarContainer.appendChild(noEvents);
    return;
  }

  for (const event of events) {
    if (event.start && event.start.dateTime && event.location) {
      const eventRow = document.createElement("div");
      eventRow.classList.add("row", "performancesRow");
      calendarContainer.appendChild(eventRow);

      const gcalCol1 = document.createElement("div");
      gcalCol1.classList.add("col-lg-4", "col-sm-4", "col-xs-4");
      eventRow.appendChild(gcalCol1);

      const gcalCol2 = document.createElement("div");
      gcalCol2.classList.add("col-lg-4", "col-sm-4", "col-xs-4");
      eventRow.appendChild(gcalCol2);

      const gcalCol3 = document.createElement("div");
      gcalCol3.classList.add("col-lg-4", "col-sm-4", "col-xs-4");
      eventRow.appendChild(gcalCol3);

      const startTime = new Date(event.start.dateTime);
      const startDate = startTime.toLocaleDateString(undefined, {
        year: "numeric",
        month: "short",
        day: "numeric",
      });
      const startTimeString = startTime.toLocaleTimeString(undefined, {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      });

      const endTime = new Date(event.end.dateTime);
      const endTimeString = endTime.toLocaleTimeString(undefined, {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      });

      const dateParagraph = document.createElement("p");
      dateParagraph.classList.add("performancesDate");
      dateParagraph.textContent = `${startDate}`;
      gcalCol1.appendChild(dateParagraph);

      const timeParagraph = document.createElement("p");
      timeParagraph.classList.add("performancesLoc2");
      timeParagraph.textContent = `${startTimeString} - ${endTimeString}`;
      gcalCol1.appendChild(timeParagraph);

      let location = event.location.split(",");
      let businessName = location[0].trim();
      let town = location[2].trim();
      let state = location[3].trim().split(/\s+/)[0].trim();

      let townName = state ? `${town}, ${state}` : town;

      let locationLink = createGoogleMapsLink(event.location);

      const townParEl = document.createElement("p");
      townParEl.classList.add("performancesLoc1");
      townParEl.textContent = townName;
      gcalCol1.appendChild(townParEl);

      const summaryParEl = document.createElement("p");
      summaryParEl.classList.add("performancesTitle1");
      const iconEl = document.createElement("i");
      iconEl.classList.add("fas", "fa-location-dot", "inline-icon");
      const businessParEl = document.createElement("p");
      businessParEl.classList.add("performancesLocHead", "inline-text");
      businessParEl.textContent = businessName;
      summaryParEl.textContent = event.summary;
      const descriptionDivEl = document.createElement("div");
      descriptionDivEl.innerHTML = event.description;
      descriptionDivEl.classList.add("performancesLoc2");
      gcalCol2.appendChild(summaryParEl);
      gcalCol2.appendChild(iconEl);
      gcalCol2.appendChild(businessParEl);
      gcalCol2.appendChild(descriptionDivEl);

      const locationButton = document.createElement("button");
      locationButton.classList.add(
        "btn",
        "btn-default",
        "btn-lg",
        "btnAction",
        "mastheadButton"
      );
      const buttonTextSpan = document.createElement("span");
      buttonTextSpan.textContent = "Directions";
      buttonTextSpan.classList.add("btnText");
      locationButton.appendChild(buttonTextSpan);
      locationButton.addEventListener("click", () => {
        window.open(locationLink, "_blank");
      });

      gcalCol3.appendChild(locationButton);
    }
  }
}

window.onload = gapiLoaded();
